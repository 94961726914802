<template>
  <div>
    <b-modal
      id="add-device-modal"
      title="Add Device"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      ok-title="Add"
      no-close-on-backdrop
    >
      <b-form @submit.stop.prevent="onSubmit">
        <Alert v-if="errorMessage" variant="red" icon="exclamation">Failed to add the device: {{ errorMessage }}</Alert>

        <b-form-group label-for="input-organization-select">
          <template slot="label">Organization <span class="text-danger">*</span></template>
          <OrganizationSelectV2
            inputName="input-organization-select"
            :value="userOrganizationId"
            @organizationSelected="organizationSelected"
            :allowEmpty="false"
            ref="multiselect"
          />
        </b-form-group>

        <b-form-group label-for="input-device-name" :invalid-feedback="veeErrors.first('input-device-name')">
          <template slot="label">Device Name <span class="text-danger">*</span></template>
          <b-form-input
            name="input-device-name"
            v-model="deviceName"
            v-validate="{ required: true, min: 5, max: 255 }"
            :state="validateState('input-device-name')"
            aria-describedby="input-device-name-feedback"
            data-vv-as="device name"
            trim
          />
        </b-form-group>
        <b-form-group
          label-for="input-device-seller-name"
          :invalid-feedback="veeErrors.first('input-device-seller-name')"
        >
          <template slot="label">Seller Name </template>
          <b-form-input
            name="input-device-seller-name"
            v-model="sellerName"
            :state="validateState('input-device-seller-name')"
            aria-describedby="input-device-name-feedback"
            data-vv-as="seller name"
            trim
          />
        </b-form-group>

        <b-form-group label-for="input-serial-number" :invalid-feedback="veeErrors.first('input-serial-number')">
          <template slot="label">
            <div class="flex">
              <p id="hover-target" class="underline">Unique Identifier</p>
              <span class="text-danger">*</span>
              <b-tooltip target="hover-target" triggers="hover" class="text-left">
                <div class="text-left">
                  <p class="text-lg">PAX A920:</p>
                  <p class="mb-2">Input the device Serial Number</p>
                  <p class="text-lg">Sunmi V2s:</p>
                  <p class="mb-2">
                    Within the device settings update the Device Name to a unique name that does not currently exist in
                    our system. Input that name here.
                  </p>
                  <p class="text-lg">Note:</p>
                  <p class="mb-2">
                    Due to security updates made to newer versions of Android a device's serial number is no longer
                    exposed to applications.
                  </p>
                </div>
              </b-tooltip>
            </div>
          </template>
          <b-form-input
            name="input-serial-number"
            v-model="serialNumber"
            v-validate="{ required: true, min: 3, max: 255 }"
            :state="validateState('input-serial-number')"
            aria-describedby="input-serial-number-feedback"
            data-vv-as="serial number"
            trim
          />
        </b-form-group>

        <b-form-group label-for="input-model-name" :invalid-feedback="veeErrors.first('input-model-name')">
          <template slot="label">Model Name <span class="text-danger">*</span></template>
          <b-form-select
            name="input-model-name"
            v-model="modelName"
            v-validate="{ required: true }"
            :state="validateState('input-model-name')"
            aria-describedby="input-model-name-feedback"
            data-vv-as="model name"
            :options="modelNameOptions"
          />
        </b-form-group>

        <b-form-group label="Active" label-for="input-active" :invalid-feedback="veeErrors.first('input-active')">
          <b-form-checkbox
            switch
            name="input-active"
            v-model="active"
            :state="validateState('input-active')"
            data-vv-as="active"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import DeviceServiceV2 from '@/lib/device-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  props: {
    userOrganizationId: {
      type: String,
      required: false
    }
  },
  components: {
    Alert,
    OrganizationSelectV2
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      organization: null,
      deviceName: null,
      sellerName: null,
      serialNumber: null,
      active: true,
      modelName: null,
      modelNameOptions: ['PAX A920', 'PAX A920 Pro', 'SUNMI T2 Mini', 'SUNMI V2', 'Unknown']
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.organization = null;
      this.deviceName = null;
      this.serialNumber = null;
      this.sellerName = null;
      this.active = true;
      this.modelName = null;
      this.errorMessage = null;
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      // Manually determine if the organization field is empty
      const multiselect = this.$refs.multiselect;
      const multiselectInput = multiselect.$el.querySelector('.multiselect__tags');
      multiselect.validateRequired();

      const valid = await this.$validator.validateAll();
      // Return if the veeValidate fields are invalid, or if the organzation one is
      if (!valid || multiselectInput.className.includes('is-invalid')) {
        return;
      }

      try {
        const params = {
          organizationId: this.organization?.id ? this.organization.id : this.userOrganizationId,
          name: this.deviceName,
          sellerName: this.sellerName,
          serialNumber: this.serialNumber,
          active: this.active,
          info: {
            modelName: this.modelName
          }
        };

        await DeviceServiceV2.addDevice(params);
        this.$emit('deviceAdded');
        this.modalShow = false;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    organizationSelected(organization) {
      this.organization = organization;
    }
  }
};
</script>
