<template>
  <div class="flex align-start">
    <div class="rounded-3xl px-4 py-0.5" :class="statusColor()">
      <p class="mb-0 text-white text-md">{{ formatStatus(this.status) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      required: true
    },
    raffleType: String
  },
  methods: {
    formatStatus(status) {
      if (status === undefined) {
        return;
      }

      if (typeof status === 'boolean') {
        return status ? 'Active' : 'Inactive';
      } else if (status === 'pending' && this.raffleType !== 'Goldrush') {
        return 'Draft';
      } else {
        return status.charAt(0).toUpperCase() + status.slice(1);
      }
    },
    statusColor() {
      switch (this.status) {
        case 'active':
        case true:
          return 'bg-green-600';
        case 'inactive':
        case false:
          return 'bg-red-600';
        case 'pending':
          return 'bg-gray-600';
        case 'ended':
          return 'bg-indigo-500';
        case 'closed':
          return 'bg-gray-800';
        default:
          return 'bg-gray-600';
      }
    }
  }
};
</script>
